import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import axios from 'axios';
import config from 'config/config';
import loadingGif from '../../assets/img/loading/loading.gif';

// Importa los componentes de framer-motion
import { motion, AnimatePresence } from 'framer-motion';

const Dashboard = () => {
  const access_level = localStorage.getItem('naseda_access_level');
  const token = localStorage.getItem('naseda_token');

  const [userInput, setUserInput] = useState('');
  const [charts, setCharts] = useState([]); // Arreglo de objetos de gráficas
  const [isLoading, setIsLoading] = useState(false);

  // Función para generar un ID único
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random()}`;
  };

  // Cargar las gráficas desde el localStorage cuando el componente se monta
  useEffect(() => {
    const storedCharts = localStorage.getItem('charts');
    if (storedCharts) {
      setCharts(JSON.parse(storedCharts));
    }
  }, []);

  // Guardar las gráficas en el localStorage cada vez que cambien
  useEffect(() => {
    localStorage.setItem('charts', JSON.stringify(charts));
  }, [charts]);

  // Handler para envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.get(`${config.backendURL}/getChartId`, {
        params: { description: userInput },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Crear un nuevo objeto de gráfica con un ID único
      const newChart = {
        id: generateUniqueId(),
        chartId: response.data.chart_id,
        filters: response.data.filters,
        title: response.data.title
      };

      // Agregar la nueva gráfica al arreglo de gráficas
      setCharts((prevCharts) => [...prevCharts, newChart]);
    } catch (err) {
      console.error('Error fetching chart ID:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveChart = (chartIdToRemove) => {
    // Eliminar la gráfica con el ID especificado
    setCharts((prevCharts) =>
      prevCharts.filter((chart) => chart.id !== chartIdToRemove)
    );
  };

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-naseda-project-emwrlmi',
      getUserToken: async () => token,
    });

    // Renderizar cada gráfica en su contenedor correspondiente
    charts.forEach((chart) => {
      const chartContainer = document.getElementById(`chart-${chart.id}`);
      if (chartContainer && !chartContainer.hasChildNodes()) {
        // Transform filters to handle multiple values with $or if necessary
        const transformedFilters = { ...chart.filters };

        // Handle order_year: array or single value
        if (chart.filters && chart.filters.order_year) {
          if (Array.isArray(chart.filters.order_year)) {
            transformedFilters.order_year = { $in: chart.filters.order_year };
          } else {
            transformedFilters.order_year = chart.filters.order_year;
          }
        }

        // Handle order_month: array or single value
        if (chart.filters && chart.filters.order_month) {
          if (Array.isArray(chart.filters.order_month)) {
            transformedFilters.order_month = { $in: chart.filters.order_month };
          } else {
            transformedFilters.order_month = chart.filters.order_month;
          }
        }

        // Handle business_branch: array or single value
        if (chart.filters && chart.filters.business_branch) {
          if (Array.isArray(chart.filters.business_branch)) {
            transformedFilters.business_branch = { $in: chart.filters.business_branch };
          } else {
            transformedFilters.business_branch = chart.filters.business_branch;
          }
        }

        const chartInstance = sdk.createChart({
          chartId: chart.chartId,
          widthMode: 'scale',
          heightMode: 'scale',
          height: '50vh',
          background: 'white',
          filter: transformedFilters, // Pass transformed filters
          renderingSpec: {
            version: 1,
            title: chart.title || 'Gráfica',
          },
        });

        chartInstance.render(chartContainer);
      }
    });
  }, [charts]);

  return (
    <>
      <Container fluid className='flex-grow-1 d-flex flex-column'>
        <div>
          <div className='search-filter-container'>
            <div className='custom-title'>
              <h1>Business Intelligence</h1>
            </div>
          </div>
          <h5>Describa la gráfica que quiere consultar:</h5>
          <form onSubmit={handleSubmit}>
            <input
              type='text'
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder='Dame los grupos de clientes'
              className='search-input mr-2'
            />
            <button type='submit' className='btn btn-primary'>
              {isLoading ? (
                <img
                  className='loading'
                  src={loadingGif}
                  alt='Cargando...'
                  style={{ height: '20px' }}
                />
              ) : (
                'Consultar'
              )}
            </button>
          </form>
        </div>

        {/* Contenedor de las gráficas con animaciones */}
        <div
          className='charts-grid mt-4'
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
            gap: '20px',
            justifyContent: 'center', // Centra las gráficas cuando hay espacio extra
          }}
        >
          <AnimatePresence>
            {charts.map((chart) => (
              <motion.div
                key={chart.id}
                layout // Habilita la animación de reordenamiento
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className='chart-container d-flex flex-column'
                style={{
                  border: '3px solid rgba(26, 38, 116, 0.3)',
                  borderRadius: '15px',
                  padding: '10px',
                  backgroundColor: 'white',
                }}
              >
                <div id={`chart-${chart.id}`} className='mb-2'></div>
                <button
                  className='btn btn-danger btn-sm align-self-start'
                  onClick={() => handleRemoveChart(chart.id)} // Pasar el ID único
                >
                  Quitar gráfica
                </button>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;