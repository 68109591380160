// src/views/UsuarioRolesPermisos.jsx

import React, { useState } from "react";
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames'; // Import classnames for tab toggling
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';
import UsuariosTable from "./UsuariosTable";
import WorkgroupTree from "components/WorkgroupTree/WorkgroupTree";

const AdminUsers = (props) => {


    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Header />
                <Container fluid className="flex-grow-1 d-flex flex-column">
                    <Container className="mt-4">
                        <UsuariosTable title="Administración de Usuarios" />
                        <div className="mb-5">

                            <h1>Jerarquía</h1>
                            <WorkgroupTree />
                        </div>
                    </Container>
                </Container>
            </div>
        </>
    );
};

export default AdminUsers;