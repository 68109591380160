// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "config/config";
import axios from "axios";
import loadingGif from "../../assets/img/loading/loading.gif";

const ConfirmAccount = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();

  // Extract the code from the URL query parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const extractedCode = searchParams.get("id");
    if (extractedCode) {
      setCode(extractedCode);
    } else {
      setPasswordError(
        "No se encontró el código de confirmación. Por favor verifica el enlace."
      );
    }
  }, [location.search]);

  const validatePassword = (password) => {
    const hasMinimumLength = password.length >= 8;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasAlpha = /[a-zA-Z]/.test(password);
    const noSequentialNumbers = !/(012|123|234|345|456|567|678|789)/.test(
      password
    );

    return (
      hasMinimumLength &&
      hasSpecialChar &&
      hasNumber &&
      hasAlpha &&
      noSequentialNumbers
    );
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    if (!validatePassword(value)) {
      setPasswordError(
        "La contraseña debe tener al menos 8 caracteres, un caracter especial, un número, y no incluir números consecutivos."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordError || confirmPasswordError || !password || !confirmPassword) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${config.backendURL}/create_password`, {
        password,
        code, // Send the extracted code
      });

      // Store session data and perform auto-login
      localStorage.setItem("naseda_token", response.data.token);
      localStorage.setItem("naseda_phone_number", response.data.phone_number);
      localStorage.setItem("naseda_name", response.data.name);
      localStorage.setItem("naseda_last_name", response.data.last_name);
      localStorage.setItem("naseda_email", response.data.email);
      localStorage.setItem("naseda_access_level", response.data.access_level);

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("naseda_token")}`;

      setIsLoading(false);
      setModalOpen(true); // Open the success modal
    } catch (error) {
      setPasswordError(
        "Se produjo un error al crear la contraseña. Por favor, inténtelo de nuevo."
      );
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) {
      // Reload the page when the modal is closed
      window.location.reload();
    }
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <h1 className="mt-5">Crea tu contraseña</h1>
          </div>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Crea tu contraseña"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Button
                  type="button"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    background: "none",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <i
                    className={`bi ${
                      showPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                  ></i>
                </Button>
              </InputGroup>
              {passwordError && (
                <div className="text-danger mt-1">{passwordError}</div>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirma tu contraseña"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <Button
                  type="button"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    background: "none",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <i
                    className={`bi ${
                      showPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                  ></i>
                </Button>
              </InputGroup>
              {confirmPasswordError && (
                <div className="text-danger mt-1">
                  Las contraseñas no coinciden.
                </div>
              )}
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <img
                    className="loading"
                    src={loadingGif}
                    alt="Cargando..."
                    style={{ height: "20px" }}
                  />
                ) : (
                  "Confirmar Cuenta"
                )}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>

      {/* Success Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader tag={'h3'} toggle={toggleModal}>Cuenta confirmada</ModalHeader>
        <ModalBody>
          <p>Tu cuenta ha sido confirmada exitosamente.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default ConfirmAccount;