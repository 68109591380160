import React, { useState } from 'react';
import {
    Container,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Header from 'components/Headers/Header';
import background from 'assets/img/brand/fondoprincipal.png';
import laboratory from 'assets/img/icons/laboratory.png';
import chemical from 'assets/img/icons/chemical.png';
import chemicalB from 'assets/img/icons/chemical2.png';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';

// Import CSS for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ResultadosVentaCruzadaArticulos = () => {
    const location = useLocation();

    // Retrieve the passed cross-sell results
    const { crossSellResults, item } = location.state || { crossSellResults: [] };

    // State for the modal
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    // Array of image options
    const imageOptions = [laboratory, chemical, chemicalB];

    // Slider settings
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
            { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
    };

    // Open modal and set selected client
    const handleViewMore = (client) => {
        setSelectedClient(client);
        setModalOpen(true);
    };

    console.log(crossSellResults);

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Container
                fluid
                className="flex-grow-1"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Top Left Title */}
                <div className="mt-4 mb-4">
                    <h2 className="text-black text-start">
                        Aquí puedes encontrar clientes que les podría interesar el producto {item?.full_name || ''}.
                    </h2>
                </div>

                {/* Carousel Container */}
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {crossSellResults.length > 0 ? (
                        <Slider {...settings} style={{ width: '80%' }}>
                            {crossSellResults[0].map((client) => (
                                <div key={client.sn_code} style={{ padding: '10px' }}>
                                    <Card
                                        className="d-flex flex-column"
                                        style={{
                                            margin: 20,
                                            border: '2px solid rgba(26, 38, 116, 0.3)',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            // height: '480px',
                                        }}
                                    >
                                        <CardImg
                                            top
                                            width="100%"
                                            src={imageOptions[Math.floor(Math.random() * imageOptions.length)]}
                                            alt={client.business_name}
                                            style={{
                                                objectFit: 'contain',
                                                height: '150px',
                                                borderRadius: '10px',
                                                padding: '5px',
                                                backgroundColor: 'white',
                                            }}
                                        />
                                        <CardBody className="d-flex flex-column flex-grow-1">
                                            <CardTitle tag="h3" className="text-center">
                                                {client.business_name || 'Sin Nombre'}
                                            </CardTitle>
                                            <CardText className="flex-grow-1 small text-center mt-2">
                                            <ReactMarkdown >

                                                {client.details.description || 'Sin descripción'}
                                                </ReactMarkdown>
                                            </CardText>
                                            <div>
                                                <CardText>
                                                    <strong>Asignado a:</strong> {client.assignee || 'No asignado'}
                                                </CardText>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    style={{ marginTop: '10px', width: '100%' }}
                                                    onClick={() => handleViewMore(client)}
                                                >
                                                    Ver más
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="text-center">No se encontraron resultados de venta cruzada.</p>
                    )}
                </div>
            </Container>

            {/* Modal for Client Details */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} centered>
                <ModalHeader tag={'h3'} toggle={() => setModalOpen(!modalOpen)}>
                    {selectedClient?.business_name || 'Detalles del Cliente'}
                </ModalHeader>
                <ModalBody>
                    {selectedClient ? (
                        <>
                        <p>
                                <strong>Descripción:</strong> <ReactMarkdown>{selectedClient.details.description || 'No disponible'}</ReactMarkdown>
                           </p>
                            <p>
                                <strong>Contacto:</strong> {selectedClient.details.contact_details.contact_name || 'No disponible'}
                            </p>
                            <p>
                                <strong>Teléfonos:</strong>
                            </p>
                            <ul>
                                {selectedClient.details.contact_details.phone_numbers.map((phone, index) => (
                                    <li key={index}>
                                        {phone.label}: {phone.number}
                                    </li>
                                ))}
                            </ul>
                            <p>
                                <strong>Emails:</strong>
                            </p>
                            <ul>
                                {selectedClient.details.contact_details.emails.map((email, index) => (
                                    <li key={index}>
                                        {email.label}: {email.email}
                                    </li>
                                ))}
                            </ul>
                            <p>
                                <strong>Productos Vendidos Previamente:</strong>
                            </p>
                            <ul>
                                {selectedClient.details.product_details.products_sold.map((product, index) => (
                                    <li key={index}>
                                        {product.product_name} - Cantidad: {product.quantity}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <p>No se encontraron detalles para este cliente.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalOpen(false)}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ResultadosVentaCruzadaArticulos;