import React, { useEffect, useRef, useState, useContext } from 'react';
import { Container, Row, Col, Input, Button } from "reactstrap";
import axios from 'axios';
import config from '../../config/config';
import naseda_img from '../../assets/img/chat/NASEDA_CIRCULO.png';
import user_img from '../../assets/img/chat/user.png';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa Bootstrap Icons
import { PageContentContext } from 'context/PageContentContext'; // Importar el contexto
import { BiSend, BiTrash } from 'react-icons/bi'; // Importar react-icons
import ReactMarkdown from 'react-markdown';

// core components
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';

const Copilot = (props) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const chatBodyRef = useRef(null);
  const messagesEndRef = useRef(null); // New ref for scrolling
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('naseda_token');
  const { setPageContent } = useContext(PageContentContext);

  useEffect(() => {
    if (messages) {
      // Create an object for the page content
      const pageContentObject = {
        purpose: 'Mis Clientes es la página donde se puede consultar los clientes ligados a usted. Puedes dar click en una empresa/cliente para consultar su información más detallada. puedes buscar alguna empresa/cliente o filtrar por "Empleado", "Grupo" o "Sucursal"',
        clientsData: messages // The actual data
      };

      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);

      setPageContent(content);
    }
  }, [messages, setPageContent]);

  const fetchExistingMessages = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.backendURL}/getNasedaCopilotMessages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data.messages && response.data.messages.length > 0) {
        setMessages(response.data.messages.reverse().map((message) => ({
          sender: message.role,
          text: message.content[0].text.value
        })));
      } else {
        const newMessage = { sender: 'assistant', text: '¿En qué te puedo ayudar?' };
        setMessages([newMessage]);
      }
    } catch (error) {
      console.error('Error fetching existing messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExistingMessages();
  }, []);

  // Scroll to the bottom whenever messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const fetchSupportResponse = async (userMessage) => {
    try {
      const response = await axios.post(`${config.backendURL}/nasedaCopilotPageQuestion`,
        {
          message: userMessage
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      return response.data.message;
    } catch (error) {
      console.error('Error fetching support response:', error);
      return 'Lo siento, algo salió mal. Por favor intenta nuevamente.';
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== '') {
      const userMessage = { sender: 'user', text: inputMessage };
      setMessages([...messages, userMessage]);
      setInputMessage('');
      setIsLoading(true);

      try {
        const supportResponse = await fetchSupportResponse(userMessage.text);
        const supportMessage = { sender: 'assistant', text: supportResponse };
        setMessages(prevMessages => [...prevMessages, supportMessage]);
      } catch (error) {
        console.error('Error during message handling:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearThreadId = async () => {
    try {
      await axios.post(`${config.backendURL}/resetCopilotConversation`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      fetchExistingMessages();
    } catch (error) {
      console.error('Error clearing conversation:', error);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "calc(100vh - 72px)",
          display: "flex",
          flexDirection: "column",
        }}
        fluid
      >
        <div style={{
          height: "calc(100vh - 100px)",
          display: "flex",
          flexDirection: "column",
          marginLeft: '120px',
          marginRight: '120px',
          boxShadow: "0 4px 60px rgba(0, 0, 0, 0.5)", // Sombra para el chat
          borderRadius: "15px",
          marginTop: '15px'
        }}>
          {/* Encabezado del chat */}
          <Row
            className="align-items-center"
            style={{
              display: 'flex', // Flexbox para organizar elementos horizontalmente
              justifyContent: 'space-between', // Espacio entre el título y el botón
              alignItems: 'center', // Centra los elementos verticalmente
              background: 'linear-gradient(to bottom, rgb(47, 37, 97), rgb(83, 35, 84))', // Fondo degradado
              padding: '20px',
              borderRadius: '15px 15px 0 0',
              color: 'white',
            }}
          >
            {/* Título */}
            <div>
              <h2 style={{ margin: 0, color: 'white' }}>Copilot</h2>
            </div>

            {/* Botón */}
            <div>
              <Button
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#ff3e3e',
                  color: 'white',
                  padding: '10px 15px',
                  border: 'none',
                }}
                onClick={handleClearThreadId}
              >
                <BiTrash size={20} style={{ marginRight: '5px' }} />
                Limpiar conversación
              </Button>
            </div>
          </Row>

          {/* Área del chat */}
          <Row style={{ flexGrow: 1, overflow: 'auto', backgroundColor: 'white' }}>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                ref={chatBodyRef}
                style={{
                  flexGrow: 1,
                  overflowY: "auto",
                  padding: "20px",
                  borderRadius: "8px",
                  maxHeight: "100%", // Ensures content doesn't overflow the container
                  backgroundColor: 'white'
                }}
              >
                {messages.map((message, index) => (
                  <div
                    className={`d-flex align-items-start mb-4 ${message.sender === 'user' ? 'flex-row-reverse' : ''
                      }`}
                  >
                    <img
                      src={message.sender === 'user' ? user_img : naseda_img}
                      className="rounded-circle"
                      alt={message.sender === 'user' ? 'User' : 'Assistant'}
                      style={{
                        width: '50px',
                        height: '50px',
                        margin: '0 10px',
                      }}
                    />
                    <div
                      className={`message-bubble ${message.sender}`}

                      style={{
                        maxWidth: '70%',

                        borderRadius: '15px',
                        padding: '10px',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <ReactMarkdown>{message.text}</ReactMarkdown>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="d-flex justify-content-center mt-3">
                    <div className="loader"></div>
                  </div>
                )}
                {/* Dummy div for scrolling */}
                <div ref={messagesEndRef} />
              </div>
            </Col>
          </Row>

          {/* Campo de entrada */}
          <Row className="py-3" style={{ flexShrink: 0, backgroundColor: 'white', borderRadius: ' 0 0 15px 15px' }}>
            <Col className="d-flex">
              <Input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder="Escribe tu mensaje..."
                className="mx-4"
                style={{
                  borderRadius: '15px'
                }}
              />
              <Button color="primary" style={{
                borderRadius: '15px'
              }} onClick={handleSendMessage}>
                <BiSend size={20} />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Copilot;