import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Table,
    Alert,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from 'reactstrap';
// import background from 'assets/img/brand/fondoprincipal.png'; // Adjust the path if necessary
import '../../assets/css/CustomTable.css';
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';
import {
    FaToggleOn,
    FaToggleOff,
    FaMailBulk,
    FaEllipsisV,
} from 'react-icons/fa'; // Import icons
import RegistrationModal from './RegistrationModal';
import UploadModal from './UploadModal';
// import Header from 'components/Headers/Header';

const UsuariosTable = ({ title }) => {
    const [sortConfig, setSortConfig] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const token = localStorage.getItem('naseda_token');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const [registrationModal, setRegistrationModal] = useState(false);
    const toggleRegistrationModal = () => {
        if (registrationModal) { // If the modal is currently open and attempting to close
            const hasUnsavedChanges =
                name.trim() !== '' ||
                lastName.trim() !== '' ||
                email.trim() !== '' ||
                phoneNumber.trim() !== '' ||
                password.trim() !== '' ||
                selectedBranches.length > 0 ||
                employeeNumber.trim() !== '' ||
                rfc.trim() !== '' ||
                selectedGroup.trim() !== '';

            if (hasUnsavedChanges) {
                const confirmClose = window.confirm(
                    'Hay campos con información. ¿Estás seguro de que quieres cerrar el modal y perder esta información?'
                );
                if (confirmClose) {
                    setRegistrationModal(false);
                    resetForm();
                }
            } else {
                setRegistrationModal(false);
            }
        } else {
            setRegistrationModal(true);
        }
    };
    // States for form fields
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [accessLevel, setAccessLevel] = useState([]);
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [rfc, setRFC] = useState('');
    const [groups, setGroups] = useState([]); // To store groups from the backend
    const [selectedGroup, setSelectedGroup] = useState(''); // To store the selected group
    const [formError, setFormError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rolesOptions, setRolesOptions] = useState([]);

    // Initialize state for selected roles
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [dashboard, setDashboard] = useState(null);

    // Handler for role selection changes
    const handleBranchChange = (newValue, actionMeta) => {
        setSelectedBranches(newValue || []);
    };

    // Custom styles for ReactSelect
    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),

    };

    // Function to reset the form
    const resetForm = () => {
        setName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setPassword('');
        setSelectedBranches([]);
        setEmployeeNumber('');
        setRFC('');
        setFormError('');
        setSelectedGroup('');
    };

    useEffect(() => {
        if (registrationModal) {
            fetchGroups();
            fetchRoles();
        }
    }, [registrationModal]);

    // Function to get groups from the backend
    const fetchGroups = async () => {
        try {
            const response = await axios.get(`${config.backendURL}/workgroupNames`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setGroups(response.data.workgroups);
        } catch (error) {
            console.error('Error fetching groups:', error);
            setGroups([]);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.get(`${config.backendURL}/allRoles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // console.log(response.data);
            setRolesOptions(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setRolesOptions([]);
        }
    };

    // Function to handle form submission
    const handleRegisterUser = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormError('');

        // Regular expressions for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneNumberRegex = /^\d{10}$/;

        // Basic validations
        if (
            !name ||
            !lastName ||
            !email ||
            !employeeNumber ||
            !rfc ||
            !selectedGroup
        ) {
            setFormError('Por favor, completa todos los campos obligatorios.');
            setIsSubmitting(false);
            return;
        }

        // Email validation
        if (!emailRegex.test(email)) {
            setFormError('Por favor, introduce un correo electrónico válido.');
            setIsSubmitting(false);
            return;
        }

        // Phone number validation
        if (!phoneNumberRegex.test(phoneNumber)) {
            setFormError('El número de teléfono debe contener exactamente 10 dígitos.');
            setIsSubmitting(false);
            return;
        }

        // Create the data object to send
        const newUser = {
            name,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            // password,
            access_level: selectedBranches.map((role) => role.value),
            employee_number: employeeNumber,
            rfc,
            group: selectedGroup,
        };

        try {
            await axios.post(`${config.backendURL}/registerUser`, newUser, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // User created successfully
            setIsSubmitting(false);
            setRegistrationModal(false);
            resetForm();
            fetchData(); // Update the user list
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setFormError(error.response.data.result);
            } else {
                setFormError('Hubo un error al registrar el usuario.');
            }
            console.error('Error creating user:', error);
            setIsSubmitting(false);
        }
    };

    // State variables for Modal
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
        setSelectedFile(null);
        setUploadError('');
        setProgress(0);
        setUploadStatus(null);
        setUploadErrors([]);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    // const [uploadSuccess, setUploadSuccess] = useState(null); // null, true, false
    const [uploadError, setUploadError] = useState('');
    const [progress, setProgress] = useState(0); // For progress bar

    const [uploadErrors, setUploadErrors] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(null); // 'success', 'partial', 'failure', or null

    const { setPageContent } = useContext(PageContentContext);

    // Debounce search term
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    // Function to handle file selection
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        // setUploadSuccess(null);
        setUploadStatus(null);
        setUploadError('');
    };

    // Function to handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError('Por favor, selecciona un archivo.');
            return;
        }

        const allowedTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ];

        if (!allowedTypes.includes(selectedFile.type)) {
            setUploadError('Solo se permiten archivos Excel (.xlsx, .xls).');
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5MB
        if (selectedFile.size > maxSize) {
            setUploadError('El archivo excede el tamaño máximo permitido de 5MB.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        setUploading(true);
        setUploadStatus(null);
        setUploadError('');
        setProgress(0);
        setUploadErrors([]);

        try {
            const response = await axios.post(
                `${config.backendURL}/uploadUsers`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            setUploading(false);
            setProgress(100);

            const results = response.data.results || [];
            const errors = results.filter((result) => result.status === 'error');

            if (errors.length > 0) {
                // Partial success
                setUploadStatus('partial');
                setUploadErrors(errors);
            } else {
                // Full success
                setUploadStatus('success');
            }

            setSelectedFile(null);
            fetchData(); // Refresh the data
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploading(false);
            setProgress(0);
            setUploadStatus('failure');

            if (error.response && error.response.data && error.response.data.message) {
                setUploadError(error.response.data.message);
            } else {
                setUploadError('Hubo un error al subir el archivo.');
            }
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const fetchData = async () => {
        const params = new URLSearchParams();
        params.append('page', currentPage);
        params.append('limit', itemsPerPage);
        // Include search term
        if (debouncedSearchTerm) {
            params.append('searchTerm', debouncedSearchTerm);
        }

        // Include sorting parameters
        if (sortConfig) {
            params.append('sortKey', sortConfig.key);
            params.append('sortDirection', sortConfig.direction);
        }

        try {
            const response = await axios.get(
                `${config.backendURL}/users?${params.toString()}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const result = response.data;
            setData(result.users); // Data for the current page
            setTotalPages(result.totalPages); // Total number of pages
        } catch (error) {
            console.error('Error fetching data', error);
            // Handle error appropriately
        } finally {
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, debouncedSearchTerm, sortConfig, token]);

    useEffect(() => {
        if (data) {
            // Create an object for the page content
            const pageContentObject = {
                purpose:
                    'Esta es la página donde puedes gestionar los usuarios del sistema. Puedes buscar usuarios y realizar acciones como ver detalles, editar o eliminar usuarios.',
                usersData: data, // The actual data
            };

            // Convert the pageContentObject to a JSON string
            const content = JSON.stringify(pageContentObject, null, 2);

            setPageContent(content);
        }
    }, [data, setPageContent]);

    // Function to handle sorting
    const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1); // Reset to first page on sort
    };

    // Function to get the sort indicator
    const getSortIndicator = (key) => {
        if (!sortConfig) return null;
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
        }
        return null;
    };

    // Pagination Component
    const PaginationComponent = () => {
        if (totalPages === 0) return null;

        const pages = [];

        // Always show the first page
        pages.push(1);

        // Calculate the page numbers to show between ellipses
        let leftSiblingIndex = Math.max(currentPage - 1, 2);
        let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

        const showLeftEllipsis = leftSiblingIndex > 2;
        const showRightEllipsis = rightSiblingIndex < totalPages - 1;

        // Add left ellipsis if needed
        if (showLeftEllipsis) {
            pages.push('...');
        } else {
            // Add pages between first page and current page
            for (let i = 2; i < leftSiblingIndex; i++) {
                pages.push(i);
            }
        }

        // Add the page numbers around the current page
        for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
            pages.push(i);
        }

        // Add right ellipsis if needed
        if (showRightEllipsis) {
            pages.push('...');
        } else {
            // Add pages between current page and last page
            for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
                pages.push(i);
            }
        }

        // Always show the last page if totalPages > 1
        if (totalPages > 1) {
            pages.push(totalPages);
        }

        return (
            <div className="pagination">
                <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                {pages.map((page, index) => {
                    if (page === '...') {
                        return (
                            <span key={`ellipsis-${index}`} className="ellipsis">
                                ...
                            </span>
                        );
                    } else {
                        return (
                            <button
                                key={page}
                                onClick={() => setCurrentPage(page)}
                                className={currentPage === page ? 'active' : ''}
                            >
                                {page}
                            </button>
                        );
                    }
                })}
                <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
        );
    };

    // Function to handle user actions
    const handleUserAction = async (userId, action, currentStatus) => {
        switch (action) {
            case 'view':
                alert(`Ver detalles del usuario con ID: ${userId}`);
                // Implement navigation or modal to show user details
                break;
            case 'credentials':
                // alert(`Ver detalles del usuario con ID: ${userId.phone_number}`);
                await handleResendCredentials(userId); 
                // Implement navigation or modal to show user details
                break;
            case 'edit':
                alert(`Editar usuario con ID: ${userId}`);
                // Implement navigation or modal to edit user
                break;
            case 'delete':
                alert(`Eliminar usuario con ID: ${userId}`);
                // Implement delete functionality
                break;
            case 'toggleActive':
                handleToggleActive(userId, currentStatus);
                break;
            default:
                break;
        }
    };

    const handleToggleActive = async (userId, currentStatus) => {
        try {
            await axios.patch(
                `${config.backendURL}/users/${userId}/active`,
                { active: !currentStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            currentStatus ? setSuccessMessage('El usuario ha sido desactivado con éxito') :  setSuccessMessage('El usuario ha sido activado con éxito');
            

            fetchData();
        } catch (error) {
            setErrorMessage('Ocurrió un error al modificar el estado del usuario. Por favor, inténtelo más tarde.')

            console.error('Error updating user status:', error);
            // Handle error appropriately, e.g., show a notification
        }
    };

    const handleResendCredentials = async (user) => {
        try {
            await axios.post(`${config.backendURL}/send_credentials`, user, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSuccessMessage('Se reenviaron las credenciales al usuario');

            fetchData();
        } catch (error) {
            setErrorMessage('Ocurrió un error al reenviar las credenciales. Por favor, inténtelo más tarde.')
            console.error('Error updating user status:', error);
            // Handle error appropriately, e.g., show a notification
        }
    }

    useEffect(() => {
        let timer;
        if (successMessage || errorMessage) {
          timer = setTimeout(() => {
            setSuccessMessage('');
            setErrorMessage('');
          }, 5000); // Alert disappears after 5 seconds
        }
    
        return () => clearTimeout(timer);
      }, [successMessage, errorMessage]);

    return (
        <>
            {/* Search Controls */}
            <div>
                <div className="search-filter-container">
                    <div className="custom-title">
                        <h1>{title}</h1>
                    </div>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Busca usuario..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            className="search-input"
                        />
                    </div>
                    <div className="upload-button-container">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle caret color="primary">
                                Alta de usuarios
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={toggleRegistrationModal}>
                                    Nuevo Usuario
                                </DropdownItem>
                                <DropdownItem onClick={toggleModal}>Carga Masiva</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                {successMessage && <Alert color="success">{successMessage}</Alert>}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

                {/* Table */}
                <Table className="custom-table">
                    <thead>
                        <tr>
                            <th onClick={() => onSort('name')}>
                                Nombre{getSortIndicator('name')}
                            </th>
                            <th onClick={() => onSort('last_name')}>
                                Apellido{getSortIndicator('last_name')}
                            </th>
                            <th onClick={() => onSort('email')}>
                                Correo Electrónico{getSortIndicator('email')}
                            </th>
                            <th onClick={() => onSort('phone_number')}>
                                Teléfono{getSortIndicator('phone_number')}
                            </th>
                            <th onClick={() => onSort('access_level')}>
                                Roles{getSortIndicator('access_level')}
                            </th>
                            <th onClick={() => onSort('active')}>
                                Estado{getSortIndicator('active')}
                            </th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((user) => (
                                <tr key={user._id}>
                                    <td>{user.name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone_number}</td>
                                    <td>{user.access_level.join(', ')}</td>
                                    <td className="text-center">
                                        <Button
                                            size="sm"
                                            style={{ color: user.active ? '#6EC531' : '#ec0928' }}
                                            onClick={() =>
                                                handleUserAction(
                                                    user.phone_number,
                                                    'toggleActive',
                                                    user.active
                                                )
                                            }
                                        >
                                            {user.active ? <FaToggleOn /> : <FaToggleOff />}
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <UncontrolledDropdown direction='left' style={{}}>
                                            <DropdownToggle
                                                tag="div"
                                                className="btn btn-link btn-sm"
                                                style={{ cursor: 'pointer', color: 'black' }}
                                            >
                                                <FaEllipsisV />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {/* <DropdownItem
                                                        onClick={() =>
                                                            handleUserAction(user.phone_number, 'view')
                                                        }
                                                    >
                                                        <FaEye /> Ver
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => handleUserAction(user._id, 'edit')}
                                                    >
                                                        <FaEdit /> Editar
                                                    </DropdownItem> */}
                                                <DropdownItem
                                                    onClick={() => handleUserAction(user, 'credentials')}
                                                >
                                                    <FaMailBulk /> Reenviar credenciales
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center' }}>
                                    Sin información
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>



                {/* Upload Modal */}
                <UploadModal
                    isOpen={modal}
                    toggle={toggleModal}
                    fetchData={fetchData}
                />

               

                {/* Registration Modal */}
                <RegistrationModal
                    isOpen={registrationModal}
                    toggle={toggleRegistrationModal}
                    fetchData={fetchData}
                />

                {/* Pagination */}
                <PaginationComponent />
            </div>
        </>
    );
};

export default UsuariosTable;