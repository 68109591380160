import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';
import '../../assets/css/Settings.css'; // Import your CSS file
import { PageContentContext } from 'context/PageContentContext';

const Settings = () => {
  const navigate = useNavigate();
  const { setPageContent } = useContext(PageContentContext);

  const navigateTo = (route) => {
    navigate(route);
  };

  useEffect(() => {
      // Create an object for the page content
      const pageContentObject = {
        purpose: 'Configueración es la página donde se puede consultar dos opciones, la administración de usuarios donde se administra a los usuarios que utilizarán la plataforma, y Roles y permisos donde se admministra el acceso a secciones y permisos para usuarios',
      };

      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);

      setPageContent(content);
  }, [setPageContent]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container fluid className="flex-grow-1 d-flex flex-column">
          {/* Centering Wrapper */}
          <div className="flex-grow-1 d-flex flex-column mt-4">
            <h1>Configuración</h1>

            {/* Card Selection */}
            <div className="d-flex justify-content-center mt-4">
              <Card
                onClick={() => navigateTo('/admin/configuracion/administracion-de-usuarios')}
                className="hover-card"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  marginRight: '10px',
                }}
              >
                <CardBody>
                  <CardTitle tag="h1">Administración de Usuarios</CardTitle>
                  <CardText>
                    Administra a los usuarios que utilizarán la plataforma.
                  </CardText>
                </CardBody>
              </Card>
              <Card
                onClick={() => navigateTo('/admin/configuracion/roles-y-permisos')}
                className="hover-card"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  marginLeft: '10px',
                }}
              >
                <CardBody>
                  <CardTitle tag="h1">Roles y Permisos</CardTitle>
                  <CardText>
                    Acceso a secciones y permisos para usuarios.
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Settings;