import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';
import Dashboard from "views/Dashboard/Dashboard";

const BusinessIntelligence = (props) => {
  return (
    <>
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Dashboard></Dashboard>

    </div>
  </>
  );
};

export default BusinessIntelligence