import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import axios from 'axios';
import config from 'config/config';
import '../../assets/css/BreadCrumbs.css';

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Import the navigate function
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [dynamicNames, setDynamicNames] = useState({});

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((x) => x && x !== 'admin'); // Ignorar 'admin'
    const crumbList = [];

    pathnames.forEach((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      const route = routes.find((r) => {
        if (r.path.includes(':')) {
          const basePath = r.path.split('/:')[0];
          return to.startsWith(basePath);
        }
        return r.path === to;
      });

      if (route) {
        if (route.path.includes(':')) {
          const paramName = route.path.split('/:')[1];
          const paramValue = pathnames[index];
          if (paramName === 'id') {
            // Solicitar el nombre dinámico basado en el ID si no está ya en el estado
            if (!dynamicNames[paramValue]) {
              axios
                .get(`${config.backendURL}/clients/${paramValue}`, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('naseda_token')}`,
                  },
                })
                .then((response) => {
                  const clientName = response.data.client.business_name;
                  setDynamicNames((prev) => ({
                    ...prev,
                    [paramValue]: clientName,
                  }));
                })
                .catch((error) => {
                  axios
                    .get(`${config.backendURL}/items/${paramValue}`, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('naseda_token')}`,
                      },
                    })
                    .then((response) => {
                      const itemName = response.data.item.full_name;
                      setDynamicNames((prev) => ({
                        ...prev,
                        [paramValue]: itemName,
                      }));
                    })
                    .catch((error) => {
                      // Si también falla la solicitud al artículo, usar 'Detalles' por defecto
                      setDynamicNames((prev) => ({
                        ...prev,
                        [paramValue]: 'Detalles',
                      }));
                    });
                });
            }
            crumbList.push({
              name: dynamicNames[paramValue] || 'Cargando...',
              path: to,
              index: index,
            });
          }
        } else {
          crumbList.push({
            name: route.name,
            path: to,
            index: index,
          });
        }
      } else {
        // Para rutas no definidas en el array de rutas
        const label = value.charAt(0).toUpperCase() + value.slice(1);
        crumbList.push({
          name: label,
          path: to,
          index: index,
        });
      }
    });

    setBreadcrumbs(crumbList);
  }, [location.pathname, dynamicNames]);

  const handleBreadcrumbClick = (index) => {
    const stepsToGoBack = (breadcrumbs.length - 1) - index;
    if (stepsToGoBack > 0) {
      navigate(-stepsToGoBack);
    } else {
      // If stepsToGoBack is 0 or negative, stay on the same page or navigate to root
      navigate('/');
    }
  };

  return (
    <div className="d-none d-md-block">

      <Breadcrumb>
        {breadcrumbs.map((crumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return isLast ? (
            <BreadcrumbItem active key={crumb.path}>
              {crumb.name}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={crumb.path}>
              <span
                onClick={() => handleBreadcrumbClick(index)}
                style={{ color: 'white', cursor: 'pointer', fontSize: 14 }}
              >
                {crumb.name}
              </span>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbsComponent;