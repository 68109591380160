// RegistrationModal.js
import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
    Button,
    Form,
    Alert,
} from 'reactstrap';
import axios from 'axios';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import config from 'config/config';

const animatedComponents = makeAnimated();

const RegistrationModal = ({ isOpen, toggle, fetchData }) => {
    // Form state
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [rfc, setRFC] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [formError, setFormError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [groups, setGroups] = useState([]);

    const token = localStorage.getItem('naseda_token');

    // Handler for role selection changes
    const handleBranchChange = (newValue) => {
        setSelectedBranches(newValue || []);
    };

    // Custom styles for ReactSelect
    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    // Function to reset the form
    const resetForm = () => {
        setName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setSelectedBranches([]);
        setEmployeeNumber('');
        setRFC('');
        setFormError('');
        setSelectedGroup('');
    };

    useEffect(() => {
        if (isOpen) {
            fetchGroups();
            fetchRoles();
        }
    }, [isOpen]);

    // Fetch groups from the backend
    const fetchGroups = async () => {
        try {
            const response = await axios.get(`${config.backendURL}/workgroupNames`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setGroups(response.data.workgroups);
        } catch (error) {
            console.error('Error fetching groups:', error);
            setGroups([]);
        }
    };

    // Fetch roles from the backend
    const fetchRoles = async () => {
        try {
            const response = await axios.get(`${config.backendURL}/allRoles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setRolesOptions(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setRolesOptions([]);
        }
    };

    // Handle form submission
    const handleRegisterUser = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormError('');

        // Regular expressions for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneNumberRegex = /^\d{10}$/;

        // Basic validations
        if (
            !name ||
            !lastName ||
            !email ||
            !employeeNumber ||
            !rfc ||
            !selectedGroup
        ) {
            setFormError('Por favor, completa todos los campos obligatorios.');
            setIsSubmitting(false);
            return;
        }

        // Email validation
        if (!emailRegex.test(email)) {
            setFormError('Por favor, introduce un correo electrónico válido.');
            setIsSubmitting(false);
            return;
        }

        // Phone number validation
        if (!phoneNumberRegex.test(phoneNumber)) {
            setFormError('El número de teléfono debe contener exactamente 10 dígitos.');
            setIsSubmitting(false);
            return;
        }

        // Create the data object to send
        const newUser = {
            name,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            access_level: selectedBranches.map((role) => role.value),
            employee_number: employeeNumber,
            rfc,
            group: selectedGroup,
        };

        try {
            await axios.post(`${config.backendURL}/registerUser`, newUser, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // User created successfully
            setIsSubmitting(false);
            toggle(); // Close the modal
            resetForm();
            if (fetchData) {
                fetchData(); // Refresh the user list
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setFormError(error.response.data.result);
            } else {
                setFormError('Hubo un error al registrar el usuario.');
            }
            console.error('Error creating user:', error);
            setIsSubmitting(false);
        }
    };

    // Handle modal toggle with unsaved changes check
    const handleToggle = () => {
        if (isOpen) {
            const hasUnsavedChanges =
                name.trim() !== '' ||
                lastName.trim() !== '' ||
                email.trim() !== '' ||
                phoneNumber.trim() !== '' ||
                selectedBranches.length > 0 ||
                employeeNumber.trim() !== '' ||
                rfc.trim() !== '' ||
                selectedGroup.trim() !== '';

            if (hasUnsavedChanges) {
                const confirmClose = window.confirm(
                    'Hay campos con información. ¿Estás seguro de que quieres cerrar el modal y perder esta información?'
                );
                if (confirmClose) {
                    toggle();
                    resetForm();
                }
            } else {
                toggle();
            }
        } else {
            toggle();
        }
    };

    return (
        <Modal isOpen={isOpen} style={{ scale: '0.9' }} toggle={handleToggle}>
            <ModalHeader tag={'h3'} toggle={handleToggle}>
                Registrar Nuevo Usuario
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleRegisterUser}>
                    <FormGroup>
                        <Label for="name">Nombre</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Apellido</Label>
                        <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Correo Electrónico</Label>
                        <Input
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="new-password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneNumber">Teléfono</Label>
                        <Input
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={phoneNumber}
                            maxLength="10"
                            onChange={(e) => {
                                // Only allow numeric characters
                                const input = e.target.value.replace(/\D/g, '');
                                setPhoneNumber(input);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="accessLevel">Roles</Label>
                        <ReactSelect
                            isMulti
                            options={rolesOptions.map((role) => ({
                                label: role.role_name,
                                value: role.role_name,
                            }))}
                            value={selectedBranches}
                            onChange={handleBranchChange}
                            placeholder="Selecciona los roles del usuario..."
                            components={animatedComponents}
                            menuPortalTarget={document.body}
                            styles={customStyles}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="employeeNumber">Número de Empleado</Label>
                        <Input
                            type="text"
                            name="employeeNumber"
                            id="employeeNumber"
                            value={employeeNumber}
                            onChange={(e) => setEmployeeNumber(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="rfc">RFC</Label>
                        <Input
                            type="text"
                            name="rfc"
                            id="rfc"
                            value={rfc}
                            onChange={(e) => setRFC(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="group">Grupo</Label>
                        <Input
                            type="select"
                            name="group"
                            id="group"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            <option value="">Selecciona un grupo</option>
                            {groups.map((group) => (
                                <option key={group} value={group}>
                                    {group}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    {formError && <Alert color="danger">{formError}</Alert>}
                    <div className="d-flex justify-content-end">
                        <Button
                            color="secondary"
                            type="button"
                            disabled={isSubmitting}
                            onClick={handleToggle}
                        >
                            Cancelar
                        </Button>
                        <Button color="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Registrando...' : 'Registrar'}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default RegistrationModal;