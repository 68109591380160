import React, { useEffect, useState } from 'react';
import '../../assets/css/WorkgroupTree.css'
import config from 'config/config';
import TreeNode from './TreeNode';
import axios from 'axios';

const WorkgroupTree = () => {
    const [data, setData] = useState([]);
    const token = localStorage.getItem('naseda_token');

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.backendURL}/workgroups`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setData(response.data.workgroups);
            } catch (err) {
                console.error('Error fetching workgroup data:', err);
            }
        };

        fetchData();
    }, [token]);

    return (
        <div className='workgroup-tree-container'>
            <div className="tree">
                <ul>
                    {data.map((workgroup) => (
                        <TreeNode key={workgroup._id} node={workgroup} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default WorkgroupTree;