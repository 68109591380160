import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../assets/css/TreeNode.css'

const TreeNode = ({ node }) => {
  const [isOpen, setIsOpen] = useState(false); // Initialize state

  const toggleOpen = (e) => {
    e.stopPropagation(); // Prevent toggling parent nodes
    setIsOpen(!isOpen);
  };

  const {
    workgroup_name,
    workgroup_leader = [],
    workgroup_user = [],
    sub_workgroup = []
  } = node;

  const hasChildren =
    workgroup_user.length > 0 ||
    sub_workgroup.length > 0;

  // Prepare leader names to include in the workgroup label
  const leaderNames = workgroup_leader
    .map((leader) => `${leader.name} ${leader.last_name}`)
    .join(', ');

  return (
    <li>
      <div
        className="node"
        onClick={hasChildren ? toggleOpen : null}
        style={{ cursor: hasChildren ? 'pointer' : 'default' }}
        aria-expanded={isOpen}
        role={hasChildren ? 'button' : 'treeitem'}
        tabIndex={hasChildren ? 0 : -1}
        onKeyPress={(e) => {
          if (hasChildren && (e.key === 'Enter' || e.key === ' ')) {
            toggleOpen(e);
          }
        }}
      >
        <div className="node-content">
          <div>
            <strong>{workgroup_name}</strong>
            {leaderNames && (
              <p style={{ fontSize: 15, margin: '5px 0' }}>{leaderNames}</p>
            )}
          </div>
          {hasChildren && (
            <span className={`arrow ${isOpen ? 'open' : ''}`}>
              {isOpen ? '▼' : '▶'}
            </span>
          )}
        </div>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="collapse"
        unmountOnExit
      >
        <ul>
          {workgroup_user.map((user, index) => (
            <li key={`user-${user.email || index}`}>
              <div className="node user-node">
                {`${user.name} ${user.last_name}`}
              </div>
            </li>
          ))}
          {sub_workgroup.map((subNode) => (
            <TreeNode key={subNode._id} node={subNode} />
          ))}
        </ul>
      </CSSTransition>
    </li>
  );
};

export default TreeNode;