// src/components/Typewriter/TypewriterText.js
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const TypewriterText = ({
  text,
  firstTime,
  setFirstTime,
  speedMin = 50,
  speedMax = 120,
  trigger,
  delay = 2000,
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const indexRef = useRef(0); // Mantiene el índice actual
  const timeoutRef = useRef(null); // Referencia para el timeout de escritura
  const delayRef = useRef(null); // Referencia para el timeout de espera

  useEffect(() => {
    // Si el trigger es falso, no hacemos nada
    if (!trigger) return;

    // Asegurarse de que 'text' es una cadena válida
    const safeText = typeof text === 'string' ? text : '';

    // Reiniciar el estado y el índice
    setDisplayedText('');
    indexRef.current = 0;

    const startTyping = () => {
      if (firstTime) {
        // Función de escritura
        const type = () => {
          // Verificar si hay más caracteres por mostrar
          if (indexRef.current < safeText.length) {
            const currentChar = safeText.charAt(indexRef.current);
            setDisplayedText((prev) => prev + currentChar);
            indexRef.current += 1;

            // Generar una velocidad aleatoria entre speedMin y speedMax
            const randomSpeed =
              Math.floor(Math.random() * (speedMax - speedMin + 1)) + speedMin;

            // Programar el siguiente carácter
            timeoutRef.current = setTimeout(type, randomSpeed);
          } else {
            // Finalizar la animación y actualizar el estado
            setFirstTime(false);
          }
        };

        type();
      } else {
        // No es la primera vez, mostrar el texto completo inmediatamente
        setDisplayedText(safeText);
      }
    };

    // Iniciar la escritura después de un retraso de 'delay' milisegundos
    delayRef.current = setTimeout(startTyping, delay);

    // Limpiar los timeouts cuando el componente se desmonta o cuando se cambia el trigger/text
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(delayRef.current);
    };
  }, [text, speedMin, speedMax, trigger, delay, firstTime, setFirstTime]);

  return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};

TypewriterText.propTypes = {
  text: PropTypes.string.isRequired,
  firstTime: PropTypes.bool.isRequired, // Indica si es la primera vez
  setFirstTime: PropTypes.func.isRequired, // Función para actualizar firstTime
  speedMin: PropTypes.number, // Velocidad mínima en ms
  speedMax: PropTypes.number, // Velocidad máxima en ms
  trigger: PropTypes.bool.isRequired, // Dispara la animación
  delay: PropTypes.number, // Tiempo de espera antes de iniciar la animación en ms
};

export default TypewriterText;