import React from 'react';
import { Container } from 'reactstrap';
import Header from 'components/Headers/Header.js';
import background from 'assets/img/brand/fondoprincipal.png';
import CustomTable from './CustomTable';
// import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";


const VentaCruzada = () => {
  const access_level = localStorage.getItem('naseda_access_level');


  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container fluid className="flex-grow-1 d-flex flex-column">
          <CustomTable
            title={
              access_level.includes('Administrador')
                ? 'Clientes'
                : 'Mis Clientes'
            }
          />
      
        </Container>
      </div>
    </>
  );
};

export default VentaCruzada;