import React, { useEffect, useRef, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import axios from 'axios';
// reactstrap components
import { Container, Spinner } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// Import your components and assets
import Chat from '../assets/img/chat/Chat.png';
import routes from "routes.js";
import SupportChatModal from "components/Modal/SupportChatModal";
import 'bootstrap-icons/font/bootstrap-icons.css';
import background from 'assets/img/brand/fondoprincipal.png'; // Ajusta la ruta según sea necesario

// Import the provider from your context (if you have one)
import { PageContentProvider } from 'context/PageContentContext';
import config from "config/config";
import Header from "components/Headers/Header";

const Admin = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const token = localStorage.getItem('naseda_token');

  // State to control the Modal
  const [supportChatOpen, setSupportChatOpen] = useState(false);

  // State to store user permissions
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSupportChatOpen = () => setSupportChatOpen(true);
  const handleSupportChatClose = () => setSupportChatOpen(false);

  //  useEffect(() => {
  //    document.documentElement.scrollTop = 0;
  //    document.scrollingElement.scrollTop = 0;
  //    mainContent.current.scrollTop = 0;
  //  }, [location]);

  // Fetch user permissions from the backend when component mounts using axios
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/userPermissions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPermissions(response.data.permissions);
        setLoading(false)

      } catch (error) {
        console.error('Error fetching permissions', error);
        localStorage.removeItem('naseda_email');
        localStorage.removeItem('naseda_last_name');
        localStorage.removeItem('naseda_name');
        localStorage.removeItem('naseda_phone_number');
        localStorage.removeItem('naseda_token');
        localStorage.removeItem('naseda_access_level');
    
        window.location.reload();
      }
    };

    fetchPermissions();
  }, []);

  // Function to filter and render routes based on permissions
  const getRoutes = (routes) => {
    if (!permissions) {
      // Permissions not loaded yet
      return null; // or a loading component
    }
    return routes
      .filter((route) => {
        // Check if route has a 'permission' property
        if (route.permission) {
          return permissions.includes(route.permission);
        }
        return true; // Include route if no permission is required
      })
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.path}
              element={prop.component}
              key={key}
              exact
            />
          );
        } else {
          return null;
        }
      });
  };

  // Filter routes for the Sidebar
  const filteredRoutes = routes.filter((route) => {
    if (route.hidden) return false; // Exclude hidden routes
    if (route.permission) {
      return permissions && permissions.includes(route.permission);
    }
    return true;
  });

  if (loading) {
    // Loading state while fetching permissions
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={filteredRoutes}
        logo={{
          innerLink: "/admin/mis-clientes",
          imgSrc: require("../assets/img/brand/logo-naseda.png"),
          imgAlt: "...",
        }}
      />
      {/* Wrap the main content with your context provider if needed */}
      <PageContentProvider>
        <div className="main-content" ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={''}
          />
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
          </Routes>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>

          {/* Support Chat Bubble */}
          <div
            className="support-bubble animate__animated animate__fadeIn"
            onClick={handleSupportChatOpen}
          >
            <img
              src={Chat}
              alt="Support Chat"
              style={{
                marginTop: '6px',
                width: '50%',
                objectFit: 'contain'
              }}
            />
          </div>
          {/* Support Chat Modal */}
          <SupportChatModal
            show={supportChatOpen}
            handleClose={handleSupportChatClose}
          />
        </div>
      </PageContentProvider>
    </>
  );
};

export default Admin;