// src/pages/DetailArticulo.js
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Spinner, Alert, Button, Card, CardBody, Collapse, CardText,
  Modal, ModalHeader, ModalBody, ModalFooter, Input
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import background from 'assets/img/brand/fondoprincipal.png';
import '../../assets/css/DetailsVentaCruzada.css';
import axios from 'axios';
import config from 'config/config';
import { FaChevronDown, FaChevronUp, FaShoppingCart } from 'react-icons/fa';
import { PageContentContext } from 'context/PageContentContext';
import ReactMarkdown from 'react-markdown';
import TypewriterText from '../../components/Typewriter/TypewriterText';

const DetailArticulo = () => {
  const { id } = useParams(); // Extraer 'id' de la URL
  const navigate = useNavigate(); // Para navegación
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState('');
  const token = localStorage.getItem('naseda_token');
  const { setPageContent } = useContext(PageContentContext);

  // Estados para secciones colapsables
  const [isTechnicalDetailsOpen, setIsTechnicalDetailsOpen] = useState(false);
  const [isItemDetailsOpen, setIsItemDetailsOpen] = useState(false);
  const [isSecurityDataOpen, setIsSecurityDataOpen] = useState(false);
  const [securityFirstTime, setSecurityFirstTime] = useState(true);
  const [detailsFirstTime, setDetailsFirstTime] = useState(true);

  // Estados para modales
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [itemURL, setItemURL] = useState('');
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateError, setGenerateError] = useState(null);
  const [generateSuccess, setGenerateSuccess] = useState(null);
  const [ventaCruzadaInput, setVentaCruzadaInput] = useState('');
  const [processLoading, setProcessLoading] = useState(false);
  const [modalError, setModalError] = useState(null);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/items/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setItem(response.data.item); // Suponiendo que la API retorna { item: {...} }

        // Establecer contenido de la página para el contexto
        const pageContentObject = {
          purpose: 'Esta es la ventana de detalles de un artículo seleccionado. En esta se puede consultar la información del artículo',
          itemData: response.data.item
        };
        const content = JSON.stringify(pageContentObject, null, 2);
        setPageContent(content);

      } catch (err) {
        console.error('Error fetching item data:', err);
        setError('Error fetching item data.');
      } finally {
        setLoading(false);
      }
    };

    fetchItem();
  }, [id, token, setPageContent]);

  const handleIniciarVentaCruzada = async () => {
    setProcessLoading(true);
    setModalError(null); // Limpiar errores previos
    await new Promise(resolve => setTimeout(resolve, 5000));
    try {
      const response = await axios.get(`${config.backendURL}/cross_sell_items`, {
        params: {
          id: id,
          input: ventaCruzadaInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const crossSellResults = response.data.cross_sell_items;

      navigate(`/admin/articulos/${id}/resultados-de-venta-cruzada`, {
        state: { crossSellResults, item },
      });
    } catch (err) {
      console.error('Error processing venta cruzada:', err);
      setGenerateError('Ocurrió un error. Inténtalo más tarde.');
    } finally {
      setProcessLoading(false);
      // Mantener el modal abierto si hay un error
      // Cerrar el modal si lo deseas:
      // setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (generateError) {
      const timer = setTimeout(() => {
        setGenerateError(null);
      }, 5000); // 5000ms = 5 segundos

      // Limpiar el temporizador cuando el componente se desmonte o si generateError cambia
      return () => clearTimeout(timer);
    }
  }, [generateError]);

  if (loading) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container className="flex-grow-1">
        {item ? (
          <div className="details-container">
            <div className="row">
              {/* Left Column */}
              <div className="col-md-8">
                <div className="left-column">
                  {/* Card Component */}
                  <Card className="mb-4">
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <CardText className='mb--1 font-italic'>{item.item_code}</CardText>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h1>{item.full_name}</h1>
                        {item.folder_path && (
                          <Button color="primary" onClick={() => window.open(item.folder_path, '_blank')} className="ml-2 mb-5">
                            Fichas
                          </Button>
                        )}
                      </div>
                      <CardText className=''>
                        <strong>Comentarios:</strong> <ReactMarkdown>{item.comments || 'Sin comentarios'}</ReactMarkdown>
                      </CardText>
                    </CardBody>
                  </Card>

                  {/* Technical Details */}
                  <Card className="mb-4">
                    <CardBody>
                      <h3 className="mt-3">Detalles Técnicos</h3>
                      <p
                        onClick={() => setIsTechnicalDetailsOpen(!isTechnicalDetailsOpen)}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      >
                        <strong>
                          Información Técnica
                          <span style={{ marginLeft: '8px' }}>
                            {isTechnicalDetailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </strong>
                      </p>
                      <Collapse isOpen={isTechnicalDetailsOpen}>
                        <p>
                          <strong>Datos Técnicos:</strong> {item.technical_name || 'N/A'}
                        </p>
                        <p>
                          <strong>Última Evaluación:</strong> {item.last_eval_date ? new Date(item.last_eval_date).toLocaleDateString() : 'N/A'}
                        </p>
                        <p>
                          <strong>Última Compra:</strong> {item.last_pur_date ? new Date(item.last_pur_date).toLocaleDateString() : 'N/A'}
                        </p>
                        <p>
                          <strong>Moneda Última Compra:</strong> {item.last_pur_cur || 'N/A'}
                        </p>
                        <p>
                          <strong>Tipo de Envío:</strong> {item.ship_type || 'N/A'}
                        </p>
                        <p>
                          <strong>Cantidad Mínima de Pedido:</strong> {item.min_ordr_qty || 0}
                        </p>
                      </Collapse>
                    </CardBody>
                  </Card>

                  {/* Item Details */}
                  <Card className="mb-4">
                    <CardBody>
                      <h3 className="mt-3">Detalles del Ítem</h3>
                      <p
                        onClick={() => setIsItemDetailsOpen(!isItemDetailsOpen)}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                      >
                        <strong style={{ display: 'flex', alignItems: 'center' }}>
                          Información del Ítem
                          <span style={{ marginLeft: '8px' }}>
                            {isItemDetailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </strong>
                        <span style={{ color: 'red', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                          <i className="bi bi-stars" style={{ marginRight: '5px' }}></i>
                          Generado por NASEDA Copilot
                        </span>
                      </p>
                      <Collapse isOpen={isItemDetailsOpen}>
                        {/* Nombre Corto */}
                        <p>
                          <strong>Nombre Corto:</strong> {item.short_name || 'Pendiente'}
                        </p>
                        {/* En Stock */}
                        <p>
                          <strong>En Stock:</strong> {item.on_stack !== undefined && item.on_stack !== null ? item.on_stack.toString() : 'Pendiente'}
                        </p>
                        {/* Unidad de Compra */}
                        <p>
                          <strong>Unidad de Compra:</strong> {item.buy_unit || 'Pendiente'}
                        </p>
                        {/* Último Precio */}
                        <p>
                          <strong>Último Precio:</strong> {item.last_price !== undefined && item.last_price !== null ? `$${item.last_price}` : 'Pendiente'}
                        </p>
                        {/* Detalles del Ítem */}
                        <p>
                          <strong>Detalles del Ítem:</strong> {item.item_details ? (
                            <TypewriterText
                            text={item.item_details}
                            trigger={isItemDetailsOpen}
                            firstTime={detailsFirstTime}
                            setFirstTime={setDetailsFirstTime}
                            speedMin={10}
                            speedMax={20}
                            delay={0}
                          />
                          ) : (
                            'Pendiente'
                          )}
                        </p>
                      </Collapse>
                    </CardBody>
                  </Card>

                  {/* Datos de Seguridad */}
                  <Card className="mb-4">
                    <CardBody>
                      <h3 className="mt-3">Datos de Seguridad</h3>
                      <p
                        onClick={() => setIsSecurityDataOpen(!isSecurityDataOpen)}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <strong style={{ display: 'flex', alignItems: 'center' }}>
                          Información de Seguridad
                          <span style={{ marginLeft: '8px' }}>
                            {isSecurityDataOpen ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </strong>
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                          }}
                        >
                          <i className="bi bi-stars" style={{ marginRight: '5px' }}></i>
                          Generado por NASEDA Copilot
                        </span>
                      </p>
                      <Collapse isOpen={isSecurityDataOpen}>
                        <p>
                          <strong>Seguridad:</strong>{' '}
                          {item.security_data ? (
                            <TypewriterText
                              text={item.security_data}
                              trigger={isSecurityDataOpen}
                              firstTime={securityFirstTime}
                              setFirstTime={setSecurityFirstTime}
                              speedMin={10}
                              speedMax={20}
                              delay={0}
                            />
                          ) : (
                            'Pendiente'
                          )}
                        </p>
                      </Collapse>
                    </CardBody>
                  </Card>

                </div> {/* Fin de la fila */}

              </div> {/* Fin de details-container */}

              <div className="col-md-4">
                <div className="left-column">
                  <Card>
                    <CardBody>
                      <h4>Información Adicional</h4>
                      <p>
                        <strong>Código:</strong> {item.item_code}
                      </p>
                      <p>
                        <strong>En Stock:</strong> {item.on_stack}
                      </p>
                      <p>
                        <strong>Último Precio:</strong> ${item.last_price}
                      </p>
                      {/* Add more additional information as needed */}
                    </CardBody>
                  </Card>

                  <Card className="mt-4">
                    <CardBody className="text-center">
                      <Button color="success" onClick={() => setIsModalOpen(true)}>
                        Venta Cruzada
                        <FaShoppingCart />
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div> {/* Fin de la fila */}

          </div>
        ) : (
          <p>No se encontró el artículo.</p>
        )}
      </Container>

      {/* Modal para Añadir Comentarios */}
      <Modal isOpen={isModalOpen} toggle={() => {
        setComments('');
        setIsModalOpen(false);
      }}>
        <ModalHeader className='pb-2' toggle={() => {
          setComments('');
          setIsModalOpen(false);
        }}>
          <h2>Añadir parámetros adicionales</h2>
        </ModalHeader>
        <ModalBody>
          {generateError && <Alert color="danger">{generateError}</Alert>}
          {generateSuccess && <Alert color="success">{generateSuccess}</Alert>}

          <Input
            type="textarea"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Ingrese parámetros adicionales que se tomarán en cuenta para la venta cruzada"
            maxLength={200}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleIniciarVentaCruzada} disabled={processLoading}>
            {processLoading ? <Spinner size="sm" /> : 'Iniciar venta cruzada'}
          </Button>{' '}
          <Button color="secondary" onClick={() => {
            setVentaCruzadaInput('');
            setModalError(null);
            setIsModalOpen(false);
          }}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DetailArticulo;