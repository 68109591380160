import BreadcrumbsComponent from "components/BreadCrumbs/BreadCrumbs";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AdminNavbar = (props) => {
  const name = localStorage.getItem('naseda_name');
  const lastName = localStorage.getItem('naseda_last_name');
  const access_level = localStorage.getItem('naseda_access_level');


  const handleLogout = async () => {
    localStorage.removeItem('naseda_email');
    localStorage.removeItem('naseda_last_name');
    localStorage.removeItem('naseda_name');
    localStorage.removeItem('naseda_phone_number');
    localStorage.removeItem('naseda_token');
    localStorage.removeItem('naseda_access_level');

    window.location.reload();
  }

  return (
    <>
      <Navbar className="navbar-top mt--1 navbar-dark align-items-center" expand="md" id="navbar-main">

        <Container fluid className="d-flex align-items-center justify-content-between">
          <BreadcrumbsComponent />

          <Form className="navbar-search navbar-search-dark mr-3 d-none d-md-flex ml-lg-auto align-items-center mb-0">

            {/* <FormGroup className="">
              <InputGroup className="input-group-alternative">
              
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup> */}
          </Form>
          <Nav className="d-none d-md-flex align-items-center mb-3" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="" nav>
                <Media className="align-items-center">
                  <Media className="">
                    <span className="text-sm font-weight-bold">
                      {name ? `${name} ${lastName}` : 'Sin nombre'}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenido!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Mi perfil</span>
                </DropdownItem>
                {access_level.includes('Administrador') &&
                  <DropdownItem to="/admin/configuracion" tag={Link}>
                    <i className="ni ni-settings-gear-65"></i>
                    <span>Configuración</span>
                  </DropdownItem>
                }
                <DropdownItem onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Cerrar sesión</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;