import React, { useState } from 'react';
import {
    Container,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Header from 'components/Headers/Header';
import background from 'assets/img/brand/fondoprincipal.png';
import laboratory from 'assets/img/icons/laboratory.png';
import chemical from 'assets/img/icons/chemical.png';
import chemicalB from 'assets/img/icons/chemical2.png';
import Slider from 'react-slick';
import axios from 'axios';

// Import CSS for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from 'config/config';

const ResultadosVentaCruzada = () => {
    const location = useLocation();

    // Retrieve the passed cross-sell results
    const { crossSellResults, client } = location.state || { crossSellResults: [] };

    // State for the modal
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [itemDetails, setItemDetails] = useState(null);

    // Array of image options
    const imageOptions = [laboratory, chemical, chemicalB];

    // Slider settings
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
            { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
    };

    // Fetch item details
    const fetchItemDetails = async (itemCode) => {
        setLoading(true);
        setItemDetails(null);

        try {
            const response = await axios.get(`${config.backendURL}/items/${itemCode}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('naseda_token')}`,
                },
            });
            setItemDetails(response.data.item);
        } catch (error) {
            console.error('Error fetching item details:', error);
        } finally {
            setLoading(false);
        }
    };

    // Open modal and fetch item details
    const handleViewMore = (item) => {
        setSelectedItem(item);
        setModalOpen(true);
        fetchItemDetails(item.item_code);
    };

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Container
                fluid
                className="flex-grow-1"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Top Left Title */}
                <div className="mt-4 mb-4">
                    <h2 className="text-black text-start">
                        Aquí puedes encontrar productos que a {client?.business_name || 'el cliente'} podría interesarle.
                    </h2>
                </div>

                {/* Carousel Container */}
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {crossSellResults.length > 0 ? (
                        <Slider {...settings} style={{ width: '80%' }}>
                            {crossSellResults.map((item) => (
                                <div key={item.item_code} style={{ padding: '10px' }}>
                                    <Card
                                        className="d-flex flex-column"
                                        style={{
                                            margin: 20,
                                            border: '2px solid rgba(26, 38, 116, 0.3)',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            height: '480px',
                                        }}
                                    >
                                        <CardImg
                                            top
                                            width="100%"
                                            src={imageOptions[Math.floor(Math.random() * imageOptions.length)]}
                                            alt={item.full_name}
                                            style={{
                                                objectFit: 'contain',
                                                height: '150px',
                                                borderRadius: '10px',
                                                padding: '5px',
                                                backgroundColor: 'white',
                                            }}
                                        />
                                        <CardBody className="d-flex flex-column flex-grow-1">
                                            <CardTitle tag="h3" className="text-center">
                                                {item.full_name || 'Sin Nombre'}
                                            </CardTitle>
                                            <CardText className="flex-grow-1 small text-center mt-2">
                                                {item.short_name || 'Sin descripción'}
                                            </CardText>
                                            <div>
                                                <CardText>
                                                    <strong>Stock Disponible:</strong> {item.on_stack || 'No disponible'}
                                                </CardText>
                                                <CardText>
                                                    <strong>Precio:</strong>{' '}
                                                    {item.last_price ? `$${item.last_price.toFixed(2)}` : 'No disponible'}
                                                </CardText>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    style={{ marginTop: '10px', width: '100%' }}
                                                    onClick={() => handleViewMore(item)}
                                                >
                                                    Ver más
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="text-center">No se encontraron resultados de venta cruzada.</p>
                    )}
                </div>
            </Container>

            {/* Modal for Item Details */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} centered>
                <ModalHeader tag={'h3'} toggle={() => setModalOpen(!modalOpen)}>
                    {selectedItem?.full_name || 'Detalles del Artículo'}
                </ModalHeader>
                <ModalBody>
                    {loading ? (
                        <div className="text-center ">
                            <Spinner color="primary" />
                        </div>
                    ) : itemDetails ? (
                        <>
                            <p>
                                <strong>Descripción resumida:</strong> {itemDetails.item_details || 'No disponible'}
                            </p>
                            <p>
                                <strong>Detalles de seguridad resumidos:</strong> {itemDetails.security_data || 'No disponible'}
                            </p>
                            <p>
                                <strong>Stock Disponible:</strong> {itemDetails.on_stack || 'No disponible'}
                            </p>

                            <p>
                                <strong>Precio:</strong>{' '}
                                {itemDetails.last_price ? `$${itemDetails.last_price.toFixed(2)}` : 'No disponible'}
                            </p>
                            <p>
                                <a href={itemDetails.folder_path}  target="_blank" >Documentos Adjuntos</a>
                            </p>
                        </>
                    ) : (
                        <p>No se encontraron detalles para este artículo.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalOpen(false)}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ResultadosVentaCruzada;