// UploadModal.js
import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Button,
    Spinner,
    Alert,
    Input,
    Label,
    Table,
} from 'reactstrap';
import { FaUpload } from 'react-icons/fa';
import axios from 'axios';
import config from 'config/config';

const UploadModal = ({ isOpen, toggle, fetchData }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [progress, setProgress] = useState(0); // Para la barra de progreso
    const [uploadErrors, setUploadErrors] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(null); // 'success', 'partial', 'failure', o null

    const token = localStorage.getItem('naseda_token');

    // Función para manejar la selección de archivos
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setUploadStatus(null);
        setUploadError('');
    };

    // Función para manejar la subida de archivos
    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError('Por favor, selecciona un archivo.');
            return;
        }

        const allowedTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ];

        if (!allowedTypes.includes(selectedFile.type)) {
            setUploadError('Solo se permiten archivos Excel (.xlsx, .xls).');
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5MB
        if (selectedFile.size > maxSize) {
            setUploadError('El archivo excede el tamaño máximo permitido de 5MB.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        setUploading(true);
        setUploadStatus(null);
        setUploadError('');
        setProgress(0);
        setUploadErrors([]);

        try {
            const response = await axios.post(
                `${config.backendURL}/uploadUsers`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            setUploading(false);
            setProgress(100);

            const results = response.data.results || [];
            const errors = results.filter((result) => result.status === 'error');

            if (errors.length > 0) {
                // Éxito parcial
                setUploadStatus('partial');
                setUploadErrors(errors);
            } else {
                // Éxito total
                setUploadStatus('success');
            }

            setSelectedFile(null);
            if (fetchData) {
                fetchData(); // Actualiza la lista de usuarios
            }
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            setUploading(false);
            setProgress(0);
            setUploadStatus('failure');

            if (error.response && error.response.data && error.response.data.message) {
                setUploadError(error.response.data.message);
            } else {
                setUploadError('Hubo un error al subir el archivo.');
            }
        }
    };

    // Función para reiniciar el estado del modal
    const resetModal = () => {
        setSelectedFile(null);
        setUploadError('');
        setProgress(0);
        setUploadStatus(null);
        setUploadErrors([]);
    };

    // Manejar el toggle del modal
    const handleToggle = () => {
        resetModal();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleToggle}>
            <ModalHeader tag={'h3'} toggle={handleToggle}>
                Subir Archivo Excel
            </ModalHeader>
            <ModalBody>
                {uploadStatus === 'success' && (
                    <Alert color="success">Usuarios cargados exitosamente.</Alert>
                )}

                {uploadStatus === 'partial' && (
                    <>
                        <Alert color="warning">
                            Algunos usuarios no se pudieron cargar:
                        </Alert>
                        <div className="error-table-container">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Teléfono</th>
                                        <th>Mensaje de error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uploadErrors.map((errorItem, idx) => (
                                        <tr key={idx}>
                                            <td>{errorItem.phoneNumber}</td>
                                            <td>{errorItem.message}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                )}

                {uploadStatus === 'failure' && (
                    <Alert color="danger">
                        {uploadError || 'Hubo un error al subir el archivo.'}
                    </Alert>
                )}

                <FormGroup>
                    <div className="custom-file-upload">
                        <Input
                            type="file"
                            name="file"
                            id="excelFile"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            disabled={uploading}
                            style={{ display: 'none' }}
                        />
                        <Label htmlFor="excelFile" className="btn btn-primary">
                            <FaUpload /> Elegir Archivo
                        </Label>
                        {selectedFile && (
                            <span className="file-name">{selectedFile.name}</span>
                        )}
                    </div>
                </FormGroup>

                {/* Mostrar progreso de subida */}
                {uploading && (
                    <div className="progress mt-3">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {progress}%
                        </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={handleToggle}
                    disabled={uploading}
                >
                    Cancelar
                </Button>
                <Button
                    color="primary"
                    onClick={handleUpload}
                    disabled={uploading || !selectedFile}
                >
                    {uploading ? (
                        <>
                            <Spinner size="sm" color="light" /> Subiendo...
                        </>
                    ) : (
                        'Subir'
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UploadModal;